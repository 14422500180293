import * as React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { val }  from '../components/cssvalue'
import {PanelWrap} from '../pages/index.js'
import {MainWrap} from '../pages/web.js'
import {TermsSection, Pdate} from '../pages/terms.js'

// STYLE
const PrivacySection = styled(TermsSection)`

`;
const Privacy = ({data, location}) => {
  return (
    <Layout location={location}>
      <Seo
        title="プライバシーポリシー"
        description=""
        pagepath={location.pathname}
      />
      <MainWrap>
        <div className="-jacket">
          <h1>プライバシーポリシー</h1>
          <p className="lead">
            TAN−ZENTを運営する鳥獣花木苑（以下「当所」といいます。）は、当所の提供する本ウェブサイトおよび付随するサービスにおける、ユーザーについての個人情報を含む利用者情報の取扱いについて、以下のとおりプライバシーポリシー（以下「本ポリシー」といいます。）を定めます。
          </p>
          <div className="-inner">
          <PrivacySection>
          <ol>
            <li>
              <p className="-ttl">収集する利用者情報および収集方法</p>
              <p className="-lead">
              本ポリシーにおいて、「利用者情報」とは、ユーザーの識別に係る情報、通信サービス上の行動履歴、その他ユーザーまたはユーザーの端末に関連して生成または蓄積された情報であって、本ポリシーに基づき当所が収集するものを意味するものとします。<br />
              本ウェブサイトにおいて当所が収集する利用者情報は、その収集方法に応じて、以下のようなものとなります。
              </p>
              <ol>
                <li>
                  ユーザーからご提供いただく情報<br />
                  本ウェブサイトを利用するために、または本ウェブサイトの利用を通じてユーザーからご提供いただく情報は、入力フォームその他当所が定める方法を通じてユーザーが入力または送信する情報となります。
                </li>
                <li>
                  ユーザーが本ウェブサイトを利用するにあたって当所が収集する情報<br />
                  当所は、本ウェブサイトへのアクセス状況やそのご利用方法に関する情報を収集することがあります。これには以下の情報が含まれます。
                  <ul>
                    <li>リファラ</li>
                    <li>IPアドレス</li>
                    <li>サーバーアクセスログに関する情報</li>
                    <li>Cookie、ADID、IDFAその他の識別子</li>
                  </ul>
                </li>
              </ol>
            </li>
            <li>
              <p className="-ttl">利用目的</p>
              <p className="-lead">
                本ウェブサイトのサービス提供にかかわる利用者情報の具体的な利用目的は以下のとおりです。
              </p>
              <ol>
                <li>ユーザーのトラフィック測定および行動測定のため</li>
                <li>本ウェブサイトに関するご依頼、お問い合わせ等への対応のため</li>
                <li>本ウェブサイトの規約等に関わる対応のため</li>
                <li>広告の配信、表示および効果測定のため</li>
              </ol>
            </li>

            <li>
              <p className="-ttl">情報収集モジュール</p>
              <p className="-lead">
                本ウェブサイトでは、以下の提携先が、ユーザーの端末にCookieを保存し、これを利用して利用者情報を蓄積および利用している場合があります。
              </p>
              <ol>
                <li>本ウェブサイトで利用しているWEB解析ツールのプライバシーポリシーについては以下、ホームページをご覧ください。<br />
                  Google Analytics: <a href="https://policies.google.com/" target="_blank" rel="noreferrer">https://policies.google.com/</a>
                </li>
              </ol>
            </li>
            <li>
              <p className="-ttl">第三者提供</p>
              <p className="-lead">
                当所は、利用者情報のうち個人情報については、次に掲げる必要がある場合を除き、あらかじめユーザーの同意を得ない第三者提供はいたしません。
              </p>
              <ol>
                <li>当所が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合</li>
                <li>合併その他の事由による事業の承継に伴って個人情報が提供される場合</li>
                <li>第3項の定めに従って、提携先または情報収集モジュール提供者へ個人情報が提供される場合</li>
                <li>国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、ユーザーの同意を得ることによって当該事務の遂行に支障を及ぼすおそれがある場合</li>
                <li>その他、個人情報の保護に関する法律その他の法令で認められる場合</li>
              </ol>
            </li>
            <li>
              <p className="-ttl">個人情報の開示</p>
              <p className="-lead">
              当所は、ユーザーから個人情報保護法の定めに基づき個人情報の開示を求められたときは、ユーザーご本人からのご請求であることを確認の上で、ユーザーに対し遅滞なく開示を行います。ただし、個人情報保護法その他の法令により、当所が開示の義務を負わない場合はこの限りではありません。
              </p>
            </li>
            <li>
              <p className="-ttl">個人情報の訂正および利用停止等</p>
              <p className="-lead">
                当所は、ユーザーから、個人情報が真実でないという理由によって個人情報保護法の定めに基づきその内容の訂正を求められた場合、およびあらかじめ公表された利用目的の範囲を超えて取扱われているという理由または偽りその他不正の手段により収集されたものであるという理由により、個人情報保護法の定めに基づきその利用の停止を求められた場合には、ユーザーご本人からのご請求であることを確認の上で遅滞なく必要な調査を行い、その結果に基づき、個人情報の内容の訂正または利用停止を行い、その旨をユーザーに通知します。なお、訂正または利用停止を行わない旨の決定をしたときは、ユーザーに対しその旨を通知いたします。当所は、ユーザーから、ユーザーの個人情報について消去を求められた場合、当所が当該請求に応じる必要があると判断した場合は、ユーザーご本人からのご請求であることを確認の上で、個人情報の消去を行い、その旨をユーザーに通知します。
              </p>
            </li>
            <li>
              <p className="-ttl">お問い合わせ窓口</p>
              <p className="-lead">
                ご意見、ご質問、苦情のお申出その他利用者情報の取扱いに関するお問い合わせは、info@choju-kaboku-en.comまでお願いいたします。
              </p>
            </li>
            <li>
              <p className="-ttl">プライバシーポリシーの変更手続</p>
              <p className="-lead">
                当所は、必要に応じて、本ポリシーを変更します。なお、本ポリシーを変更する場合には、変更後の本ポリシーの施行時期および内容を当所のウェブサイト上での表示その他の適切な方法により周知します。
              </p>
            </li>
            </ol>
          </PrivacySection>
          <Pdate>
            2021年10月23日制定
          </Pdate>
          </div>
        </div>
      </MainWrap>
    </Layout>
  )
}
export default Privacy
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
        description
      }
    }
  }
`
