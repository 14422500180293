import * as React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { val }  from '../components/cssvalue'
import {PanelWrap} from '../pages/index.js'
import {MainWrap} from '../pages/web.js'
import { media } from '../components/style-utils';

// STYLE
export const TermsSection = styled.section`
  ${media.sp`
    margin: 0 5vw;
  `}
  h2 {
    font-size: 16px;
  }
  > ol {
    font-size: 12px;
    ol {
      padding-inline-start: 2em;
      > li {
        list-style-type: none;
        counter-increment: cnt;
        text-indent: -2em;
        &::before {
          content: "(" counter(cnt) ") ";
        }
        li {
          text-indent: 0;
        }
        ul {
          padding-inline-start: 1.5em;
        }
      }
    }
  }
 .moku {

 }
`;
export const Pdate = styled.p`
  font-size: 12px;
  text-align: right;
  margin-top: 40px;
`;
const Terms = ({data, location}) => {
  return (
    <Layout location={location}>
      <Seo
        title="利用規約・プライバシーポリシー"
        description=""
        pagepath={location.pathname}
      />
      <MainWrap>
        <div className="-jacket">
          <h1>利用規約</h1>
          <div className="-inner">

          <TermsSection>
            <h2>第1条（適用）</h2>
            <ol>
              <li>本規約は、本ウェブサイトの提供および本ウェブサイトの利用に関するTAN−ZENTを運営する鳥獣花木苑（以下「当所」といいます。）と利用ユーザーとの間の権利義務関係を定めることを目的とし、利用ユーザー（以下「ユーザー」といいます。）と当所との間の本ウェブサイトの利用に関わる一切の関係に適用されます。 </li>
              <li> 本規約の内容と、本ウェブサイトに記載された他のルールその他の本規約外における本ウェブサイトの説明等とが異なる場合は、本規約の規定が優先して適用されるものとします。 </li>
            </ol>
          </TermsSection>
          <TermsSection>
            <h2>第2条（禁止事項）</h2>
            <ol>
              <li>本ウェブサイトの利用にあたり、以下の各号のいずれかに該当する行為または該当すると当所が判断する行為をしてはなりません。
                <ol>
                  <li>当所、または第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利または利益を侵害する行為</li>
                  <li>法令に違反する行為または犯罪行為に関連する行為 </li>
                  <li>当所、本ウェブサイトの他の利用者またはその他の第三者に対する詐欺または脅迫行為</li>
                  <li>公序良俗に反する行為</li>
                  <li>本ウェブサイトのネットワークまたはシステム等に過度な負荷をかける行為 </li>
                  <li>本ウェブサイトの運営を妨害するおそれのある行為</li>
                  <li>当所のネットワークまたはシステム等への不正アクセス</li>
                  <li>コンピュータウィルス等有害なプログラムを使用または提供する行為</li>
                  <li>第三者に成りすます行為</li>
                  <li>当所が事前に許諾しない本ウェブサイト上での宣伝、広告、勧誘、または営業行為</li>
                  <li>反社会的勢力等への利益供与 </li>
                  <li>前各号の行為を直接または間接に惹起し、または容易にする行為</li>
                  <li>前各号の行為を試みること</li>
                  <li>その他、当所が不適切と判断する行為</li>
                </ol>
                </li>
            </ol>
          </TermsSection>
          <TermsSection>
            <h2>第3条（本ウェブサイトの停止、内容の変更、終了等）</h2>
            <ol>
              <li>当所は、以下のいずれかに該当する場合には、予告なく、本ウェブサイトの全部または一部の提供を停止または終了することができるものとします。</li>
              <li>本ウェブサイトに係るコンピューター・システムの点検または保守作業を緊急に行う場合</li>
              <li>コンピューター、通信回線等の障害、誤操作、過度なアクセスの集中、不正アクセス、ハッキング等により本ウェブサイトの運営ができなくなった場合。</li>
              <li>地震、落雷、火災、風水害、停電、天災地変などの不可抗力により本ウェブサイトの運営ができなくなった場合。</li>
              <li>その他、当所が停止または終了を必要と判断した場合。</li>
              <li>前各項は内容の変更についても同様とします。</li>
            </ol>
          </TermsSection>
          <TermsSection>
            <h2>第4条（権利帰属）</h2>
            <ol>
              <li>当所ウェブサイトおよび本ウェブサイトに関する知的財産権は全て当所または当所にライセンスを許諾している者に帰属しており、本規約に基づく本ウェブサイトの利用許諾は、当所ウェブサイトまたは本ウェブサイトに関する当所または当所にライセンスを許諾している者の知的財産権の使用許諾を意味するものではありません。 </li>
              <li>当所から送信する電子メールの内容に関する著作権も、全て当所または当所にライセンスを許諾している者に帰属します。</li>
            </ol>
          </TermsSection>
          <TermsSection>
            <h2>第5条（免責事項）</h2>
            <ol>
              <li>当所は、本ウェブサイトに掲載されている全ての情報について、その正確性および完全性等に関していかなる保証もするものではありません。</li>
              <li>ユーザーが本ウェブサイトを利用したこと、または利用できなかったことによりユーザーに生じる一切の損害および第三者によるデータの書き込み、不正なアクセス、発言、メールの送信等に関して生じる一切の損害について、当所は、何ら責任を負うものではありません。</li>
            </ol>
          </TermsSection>
          <TermsSection>
            <h2>第6条（利用者情報の取扱い）</h2>
            <ol>
              <li>当所は、本ウェブサイトで取得したユーザーの個人情報を別途当所のプライバシーポリシーの定めにより適切に扱います。ユーザーはこのプライバシーポリシーに従って当所が登録ユーザーの利用者情報を取扱うことについて同意するものとします。</li>
              <li>当所は、ユーザーが当所に提供した情報、データ等を、個人を特定できない形での統計的な情報として、当所の裁量で、利用および公開することができるものとし、ユーザーはこれに異議を唱えないものとします。 </li>
            </ol>
          </TermsSection>
          <TermsSection>
            <h2>第7条（本規約等の変更）</h2>
            <ol>
              <li> 当所は、当所が必要と認めた場合は、本規約を変更できるものとします。本規約を変更する場合、変更後の本規約の施行時期および内容を当所ウェブサイト上での掲示その他の適切な方法により周知します。</li>
            </ol>
          </TermsSection>
          <TermsSection>
            <h2>第8条（分離可能性）</h2>
            <ol>
              <li> 本規約のいずれかの条項またはその一部が、消費者契約法その他の法令等により無効または執行不能と判断された場合であっても、本規約の残りの規定および一部が無効または執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。</li>
            </ol>
          </TermsSection>
          <TermsSection>
            <h2>第9条（Cookieおよび類似情報について）</h2>
            <ol>
              <li>本ウェブサイトではご利用の端末から送信される、クッキー（Cookie）等を利用して、IPアドレス、閲覧したページの情報、閲覧環境の情報等を取得しています。<br />
              これらの情報は以下の目的で利用いたします。
                <ol>
                  <li>ユーザーのウェブサイト利用状況を分析し、よりよいウェブサイトをご利用いただくため</li>
                  <li>ユーザーのウェブサイト利用状況を分析し、よりよいサービスを提供するため</li>
                  <li>ユーザーのウェブサイト利用状況を分析し、本ウェブサイトの利用状況を把握するため</li>
                </ol>
              </li>
              <li>
              本ウェブサイトで利用しているWEB解析ツールのプライバシーポリシーについては以下、ホームページをご覧ください。<br />
                <a href="https://policies.google.com/" target="_blank" rel="noreferrer">https://policies.google.com/</a>
              </li>
            </ol>
          </TermsSection>
          <TermsSection>
            <h2>第10条（準拠法および管轄裁判所）</h2>
            <ol>
              <li>本規約および本ウェブサイトの準拠法は日本法とします。</li>
              <li>本規約または本ウェブサイトに起因し、または関連する一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。</li>
            </ol>
          </TermsSection>
          <Pdate>
            2021年10月23日制定
          </Pdate>
          </div>
        </div>
      </MainWrap>
    </Layout>
  )
}
export default Terms
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
        description
      }
    }
  }
`
